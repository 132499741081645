/* 
BLUE-PRIMARIO: #11526F
BLUE-SECONDARIO: #36CAF6
*/

.MuiDataGrid-columnHeaders {
  background-color: #11526f;
  color: white;
  border-radius: 0.125rem !important;
}

.MuiDataGrid-root {
  border: None !important;
}
.MuiDataGrid-row {
  background-color: #ffffff;
}

body {
  background-color: #f5f8fa;
}

#Locker:hover + #LockerUP {
  display: none !important;
}

.Toastify__toast--warning {
  background: red;
  color: white;
}

.MuiDataGrid-pinnedColumnHeaders {
  background-color: #0b3447 !important;
}

.MuiDataGrid-footerContainer {
  font-weight: bold !important;
}

.MuiDataGrid-columnSeparator {
  color: #11526f !important;
}

/*
BORDEAUX :  #9E0013
VERDE    :  #51E838
ROSSO    :  #FF5757
VIOLA    :  #9059C3 
BLU      :  #11526F

*/
