@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100vh;
  @apply bg-slate-200;
}

html.dark {
  background-color: #11526f;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.light-logo {
  background-image: url("./assets/images/Logo-chiaro.png");
}

.benvenuto-effect {
  @apply text-black p-4 transition-all rounded-lg cursor-pointer hover:bg-[rgb(238,242,246)] hover:dark:bg-primary-500 dark:text-white;
}

.link-sidebar {
  @apply uppercase py-3 font-semibold block dark:text-white;
}
.text-sidebar-hover {
  @apply transition-all p-1 px-2 rounded-lg cursor-pointer z-10 hover:bg-[rgb(238,242,246)] hover:p-2;
}

.text-underline-hover {
  @apply transition-all p-1 px-2 rounded-lg cursor-pointer hover:underline hover:bg-[rgb(238,242,246)] hover:dark:bg-primary-500 dark:text-white;
}
.selectedNav {
  @apply bg-[#060044] text-white block px-3 py-2 rounded-lg text-base font-medium cursor-pointer text-center;
}
.otherNav {
  @apply text-gray-300 hover:bg-[#1F5FD3] hover:text-white block px-3 py-2 rounded-lg text-base font-medium cursor-pointer text-center;
}

.btnMiller {
  @apply flex flex-row mx-2 justify-center items-center gap-x-2 transition duration-300 text-white text-center rounded-md bg-primary-600 hover:bg-primary-100 uppercase;
}

.btnMillerError {
  @apply flex flex-row mx-2 justify-center items-center gap-x-2 transition duration-300 text-white text-center rounded-md bg-[#ff5757] hover:bg-[#990000] uppercase;
}

.btnMillerLightBlue {
  @apply flex flex-row mx-2 justify-center items-center gap-x-2 transition duration-300 text-white text-center rounded-md bg-[#83bce5] hover:bg-[#94CDF6]  uppercase;
}

.btnExportScad {
  @apply w-full  transition-all hover:bg-transparent bg-primary-500 hover:text-primary-700 text-white px-2 border hover:border-primary-500 border-transparent rounded py-[8.5px] text-[1.1em];
}
.btnExport {
  @apply w-full  transition-all hover:bg-transparent bg-primary-500 hover:text-primary-700 text-white px-2 border hover:border-primary-500 border-transparent rounded py-[8.5px] text-[1.1em];
}
.btnAcquista {
  @apply w-full transition-all hover:bg-white bg-[#11526f] hover:text-primary-700 text-white font-semibold px-1 border hover:border-primary-500 border-transparent rounded py-[8.5px] mt-2;
}
.pieTrue {
  @apply w-full lg:w-1/3 flex flex-col p-6 mx-auto rounded-lg xl:p-4 bg-azulMiller text-white;
}
.pieFalse {
  @apply w-full lg:w-1/3 flex flex-col p-6 mx-auto rounded-lg xl:p-4 bg-azulMiller text-white  cursor-pointer duration-200 relative;
}
.pieTitle {
  @apply mb-4 text-2xl font-semibold flex items-center justify-center;
}

.MuiDataGrid-toolbarContainer > button {
  color: #11526f !important;
}


.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: "red" !important;
}

.ButtonMiller{
  background-color: #186e96!important;
}
.ButtonMiller:hover{
  @apply border hover:border-primary-500 ;
  background-color: white!important;
  color: #186e96!important;

}

#GridFooterColumnsButton {
  color: #11526f !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
}
.ButtonToolbarWrapped {
  background-color: white !important;
  /* border: #11526f 1.5px solid !important; */
  color: #11526f !important;
  box-shadow: none !important;
}

#CounterFooter{

  border:0px!important;
  
  }
/* :root {
  --primary: #11526f;
  --secondary: #36caf6;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --locked-color: #11526f;
  --unlocked-color: #ffffff;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 400px; */
}
/* :::::::::::::: Required CSS */
/* Locked */
.lock {
  width: 72px;
  height: 54px;
  border: 5px solid var(--locked-color);
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.lock:after {
  content: "";
  display: block;
  background: var(--locked-color);
  width: 9px;
  height: 21px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  margin: -10.5px 0 0 -6px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.lock:before {
  content: "";
  display: block;
  width: 48px;
  height: 30px;
  bottom: 100%;
  position: absolute;
  left: 50%;
  margin-left: -24px;
  border: 5px solid var(--locked-color);
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom: 0;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
/* Locked Hover */
.lock:hover:before {
  height: 36px;
}
/* Unlocked */
.unlocked {
  transform: rotate(10deg);
}
.unlocked:before {
  bottom: 130%;
  left: 31%;
  margin-left: -34.5px;
  transform: rotate(-45deg);
}
.unlocked,
.unlocked:before {
  border-color: var(--unlocked-color);
}
.unlocked:after {
  background: var(--unlocked-color);
}
/* Unlocked Hover */
.unlocked:hover {
  transform: rotate(3deg);
}
.unlocked:hover:before {
  height: 30px;
  left: 40%;
  bottom: 124%;
  transform: rotate(-30deg);
}

/* RADDOPPIATO


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --locked-color: #11526f;
  --unlocked-color: #ff5153;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.lock {
  width: 48px;
  height: 42px;
  border: 4px solid var(--locked-color);
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.lock:after {
  content: "";
  display: block;
  background: var(--locked-color);
  width: 6px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  margin: -7px 0 0 -4px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.lock:before {
  content: "";
  display: block;
  width: 32px;
  height: 20px;
  bottom: 100%;
  position: absolute;
  left: 50%;
  margin-left: -16px;
  border: 4px solid var(--locked-color);
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom: 0;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.lock:hover:before {
  height: 24px;
}
.unlocked {
  transform: rotate(10deg);
}
.unlocked:before {
  bottom: 130%;
  left: 31%;
  margin-left: -23px;
  transform: rotate(-45deg);
}
.unlocked,
.unlocked:before {
  border-color: var(--unlocked-color);
}
.unlocked:after {
  background: var(--unlocked-color);
}
.unlocked:hover {
  transform: rotate(3deg);
}
.unlocked:hover:before {
  height: 20px;
  left: 40%;
  bottom: 124%;
  transform: rotate(-30deg);
} */
